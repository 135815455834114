const TeamTwo = () => {
   return (
      <>
         <section className="about__area pb-100 pt-100 bg-light p-relative">
            <div className="container">
               <div className="row flex-column-reverse flex-md-row">
                  <div className="col-xl-5 col-lg-5 pr-0 ">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Mrinmoy Chakraborty</h3>
                           <span>Founding CEO, SOLiD Inspire </span>
                        </div>
                        <p>Mrinmoy is the Founding CEO of SOLiD Inspire, bringing over 23 years of global experience in leading technology-driven transformations across Korea, India, the US, Saudi Arabia, and the UK.</p>
                        <p>Throughout his career, he has successfully spearheaded industry-defining digital transformations in sectors such as retail logistics, manufacturing, banking, commercial real estate, and transportation. Prior to founding SOLiD Inspire, Mrinmoy held leadership positions at Cypress Semiconductor, Xchanging, and OnMobile.</p>
                        <p>After completing his Master’s in Business Management from Stanford Graduate School of Business as a Sloan Fellow, he co-founded his first IoT startup in 2010 and has since been a serial tech-preneur. He is currently pursuing a Doctorate in Business Management from Warwick Business School, focusing on the application of Artificial Intelligence in developing transformation strategies.</p>
                        <p>Mrinmoy also holds an MBA from IIM Mumbai, where he graduated as a Gold Medalist, and a Bachelor’s degree in Electrical Engineering (Honors) from Jadavpur University, Kolkata.</p>
                        <p>A prolific innovator, Mrinmoy is the holder of 24 international patents.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 offset-xl-1  offset-lg-1">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/mrinmoy-chakraborty.jpg" alt="Mrinmoy Chakraborty" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamTwo;